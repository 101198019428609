import React from 'react'

import News from './news'
import PortableText from '../../serializers/portableText'
import Container from '../../container'
import styles from '../page.module.css'

const NewsPage = ({title, _rawBody, posts}) => {
  return (
    <>
      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>

          <div className={styles.mainContent}>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>

          {/* post */}
          <News
            posts={posts}
          />

        </Container>
      </article>
    </>
  )
}
export default NewsPage
