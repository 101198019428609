import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import PortableText from '../../serializers/portableText'
import styles from './news.module.css'
// import LeftChevron from '../../../images/icons/left-chevron.svg'

const Post = ({posts}) => {
  // console.log({posts})
  return (
    <>
      <div className={styles.postsWrapper}>
        {posts.edges.map(post => (
          <div key={post.node._id} className={styles.postWrapper}>

            <div className={styles.postContentWrapper}>
              <h3><Link to={`/news/${post.node.slug.current}`} className={styles.postLink}>{post.node.title}</Link></h3>

              <p className={styles.postDate}>{post.node.postDate}</p>

              <p className={styles.postCategory}>{post.node.postCategory}</p>

              {post.node._rawTeaser && (<div> <PortableText blocks={post.node._rawTeaser} /> </div>)}
            </div>

            {post.node.teaserImage && (<div className={styles.teaserImage}><Link to={`/news/${post.node.slug.current}`}><Img fluid={post.node.teaserImage.asset.fluid} alt={post.node.teaserImage.alt} /></Link></div>)}
          </div>
        ))}

        {/* <p className={styles.postDate}>{postDate}</p>

        <p className={styles.postCategory}>{postCategory}</p>

        {_rawBody && (<div> <PortableText blocks={_rawBody} /> </div>)}

        {teaserImage && (<div className={styles.teaserImage}><Img fluid={teaserImage.asset.fluid} alt={teaserImage.alt} /></div>)}

        <p> <Link to={`/news`} className={styles.moreinfoBtn}><span><LeftChevron /> Back to News</span></Link></p> */}

      </div>
    </>

  )
}
export default Post
